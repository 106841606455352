<template>
    <div id="totop" class="no-header-template pattern-bg form-sent">
        <div class="container-espe" style="text-align: center;">
            <!-- risposta al form di richiesta di confronto con un esperto -->
            <div v-if="this.formResults.type=='richiesta-confronto'">
                
                <img src="../assets/sent.svg" alt="">

                <!--<h2>LA TUA RICHIESTA È STATA INVIATA {{ ' A ' + this.formResults.expert_contact }}!</h2>-->
                <p class="bold">A breve sarai contattato dallo staff di ExpeRotary e riceverai le istruzioni per contattare l’esperto.</p>

                <p class="bold" v-if="this.formResults.donation_amount>0">
                    Grazie per aver donato {{ this.formResults.donation_amount }}€ al progetto {{ this.formResults.donation_project }}
                </p>
                <img class="donated" v-if="this.formResults.donation_amount>0" src="../assets/donated.svg" alt="">
                <p v-if="this.formResults.donation_amount>0">
                    ExpeRotary aggiungerà altri {{ this.formResults.donation_amount }}€ per raddoppiare l'impatto della tua donazione!
                </p>

                <div class="button-container">
                    <a href="/gli-esperti" class="btn" v-if="this.formResults.expert_contact">TORNA AGLI ESPERTI</a>
                </div>
            </div>

            <!-- risposta alla donazione -->
            <div v-if="this.formResults.type=='donazione-spontanea'">
                <h2>{{ this.formResults.name + " " + this.formResults.surname }}, GRAZIE PER IL TUO SUPPORTO.</h2>

                <img src="../assets/donated.svg" alt="">

                <p class="bold">Grazie per aver donato {{ this.formResults.donation_amount }}€ al progetto {{ this.formResults.donation_project }}</p>
                <p>ExpeRotary aggiungerà altri {{ this.formResults.donation_amount }}€ per raddoppiare l'impatto della tua donazione!</p>

                <div class="button-container">
                    <a href="/chi-supportiamo" class="btn" v-if="!this.formResults.expert_contact && this.formResults.donation_amount>0">TORNA AI PROGETTI</a>
                </div>
            </div>

            <!-- risposta al form di feedback -->
            <div v-if="this.formResults.type=='feedback'">
                <h2>{{ this.formResults.name + " " + this.formResults.surname }}, GRAZIE PER AVER LASCIATO UN FEEDBACK.</h2>
                <p></p>

                <img src="../assets/sent.svg" alt="">

                <div class="button-container">
                    <a href="/" class="btn" v-if="!this.formResults.expert_contact && this.formResults.donation_amount<=0">VAI ALLA HOMEPAGE</a>
                </div>
            </div>

            <!-- risposta al form per diventare un esperto -->
            <div v-if="this.formResults.type=='diventa-esperto'">
                <h2>{{ this.formResults.name + " " + this.formResults.surname }}, GRAZIE PER AVERCI CONTATTATO.</h2>
                <p>A breve sarai ricontattato dallo staff di Experotary per maggiori informazioni.</p>

                <img src="../assets/sent.svg" alt="">
                <div class="button-container">
                    <a href="/" class="btn" v-if="!this.formResults.expert_contact && this.formResults.donation_amount<=0">TORNA ALLA HOMEPAGE</a>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    name: 'confirmed',
    components: {
    },
    data: () => {
        return {
            pageData: { 
                attributes: {
                    title: "",
                    body: {
                        summary: ""
                    }
                } 
            },
            currentPageId: ""
        }
    },
    methods: {  
    },
    computed: {
        formResults() {
            return this.$store.state.sentForm
        },
        lang() {
            return this.$store.state.currentLang
        },
        defaultLang() {
            return this.$store.state.defaultLang
        }
    },
    mounted() {
        /*setTimeout(() => {
            this.$router.push('/')
        }, 7000);*/
    }
}
</script>

<style lang="scss">
img {
    margin: 0 auto;
}

.donated {
    width: 80px;
    height: 80px;
}
</style>